import React from "react";
import { concatClassNames as cn } from "@system42/core";

import { AutoLink } from "../helpers/AutoLink";
import { Image } from "../Image";

import * as styles from "./styles.module.css";

export function CompareCard(props) {
  const {
    title,
    text,
    linkTo,
    linkHref,
    backgroundImage,
    color,
    image,
    className,
  } = props;

  return (
    <div className={styles.compareCard}>
      <AutoLink
        className={cn(className, styles.card)}
        style={{ backgroundImage, color }}
        href={linkHref}
        to={linkTo}
      >
        <div className={cn(styles.title, styles.title_desktop)}>{title}</div>
        {image && <Image image={image} className={styles.image} />}
        <div className={styles.text}>{text}</div>
        <div className={styles.hoverOpenIndicator}>Read more</div>
      </AutoLink>
      <div className={cn(styles.title, styles.title_mobile)}>{title}</div>
    </div>
  );
}

CompareCard.Group = function CompareCardGroup(props) {
  const { className, ...restProps } = props;
  return <div className={cn(styles.group, className)} {...restProps} />;
};
