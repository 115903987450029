import React from "react";
import { HighlightedText, concatClassNames as cn } from "../system42";
import Layout from "../layouts/layout";
import { GetStartedBox } from "../components/GetStartedBox";
import * as helperStyles from "./helpers.module.css";
import * as styles from "./styles/compare.module.css";
import { HeaderOverlap } from "../components/HeaderOverlap";

import { Testimonials } from "../components/Testimonials";
import {
  testimonialAaron,
  testimonialDamon,
  testimonialJamie,
  testimonialJoshua,
  testimonialPascal,
  testimonialPaul,
  testimonialThomas,
} from "../helpers";
import { StaticImage } from "gatsby-plugin-image";
import { Headline } from "../components/Headline";
import { CompareCard } from "../components/CompareCard";
import { BlogTeaser } from "../components/BlogTeaser";

// import imageBackgroundCrazyEgg from '../images/compare/compareCard/bg-crazyegg.svg'
// import imageBackgroundLoop11 from '../images/compare/compareCard/bg-loop11.svg'
// import imageBackgroundOptimalWorkshop from '../images/compare/compareCard/bg-optimalworkshop.svg'
// import imageBackgroundOptimizely from '../images/compare/compareCard/bg-optimizely.svg'
// import imageBackgroundRapidUserTests from '../images/compare/compareCard/bg-rapidusertests.svg'
// import imageBackgroundTestbirds from '../images/compare/compareCard/bg-testbirds.svg'
import imageBackgroundTryMata from "../images/compare/compareCard/bg-trymata.svg";
// import imageBackgroundUsabilityHub from '../images/compare/compareCard/bg-usabilityhub.svg'
// import imageBackgroundUseberry from '../images/compare/compareCard/bg-useberry.svg'
import imageBackgroundUserfeel from "../images/compare/compareCard/bg-userfeel.svg";
import imageBackgroundUserlytics from "../images/compare/compareCard/bg-userlytics.svg";
import imageBackgroundUserTesting from "../images/compare/compareCard/bg-usertesting.svg";
import imageBackgroundUserZoom from "../images/compare/compareCard/bg-userzoom.svg";
import { TrustBox } from "../components/TrustBox";
// import imageBackgroundUxTweak from '../images/compare/compareCard/bg-uxtweak.svg'

const ContentPage = () => {
  const listCenteredAllCompareArticles = [
    {
      title: "Userbrain vs. UserTesting",
      text: "Trying to choose between UserTesting and Userbrain?",
      linkTo: "/compare/usertesting",
      color: "white",
      backgroundImage: `url(${imageBackgroundUserTesting})`,
      image: (
        <StaticImage
          width="166"
          src="../images/compare/compareCard/logos/usertesting-userbrain-fs8.png"
          alt="UserTesting vs. Userbrain"
          placeholder="none"
        />
      ),
    },
    {
      title: "Userbrain vs. TryMata",
      text: "Trying to choose between TryMata and Userbrain?",
      linkTo: "/compare/trymata",
      color: "white",
      backgroundImage: `url(${imageBackgroundTryMata})`,
      image: (
        <StaticImage
          width="166"
          src="../images/compare/compareCard/logos/trymata-userbrain-fs8.png"
          alt="TryMata vs. Userbrain"
          placeholder="none"
        />
      ),
    },
    {
      title: "Userbrain vs. Userlytics",
      text: "Trying to choose between Userlytics and Userbrain?",
      linkTo: "/compare/userlytics",
      color: "white",
      backgroundImage: `url(${imageBackgroundUserlytics})`,
      image: (
        <StaticImage
          width="166"
          src="../images/compare/compareCard/logos/userlytics-userbrain-fs8.png"
          alt="Userlytics vs. Userbrain"
          placeholder="none"
        />
      ),
    },
    {
      title: "Userbrain vs. UserZoom",
      text: "Trying to choose between UserZoom and Userbrain?",
      linkTo: "/compare/userzoom",
      backgroundImage: `url(${imageBackgroundUserZoom})`,
      image: (
        <StaticImage
          width="166"
          src="../images/compare/compareCard/logos/userzoom-userbrain-fs8.png"
          alt="UserZoom vs. Userbrain"
          placeholder="none"
        />
      ),
    },
    {
      title: "Userbrain vs. Userfeel",
      text: "Trying to choose between Userfeel and Userbrain?",
      linkTo: "/compare/userfeel",
      color: "white",
      backgroundImage: `url(${imageBackgroundUserfeel})`,
      image: (
        <StaticImage
          width="166"
          src="../images/compare/compareCard/logos/userfeel-userbrain-fs8.png"
          alt="Userfeel vs. Userbrain"
          placeholder="none"
        />
      ),
    },
  ];

  const listCenteredAllAlternativeBlogArticles = [
    {
      title: "Top 6 UsabilityHub Alternatives for UX/UI Designers in 2024",
      linkHref: "https://www.userbrain.com/blog/usabilityhub-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/usabilityhub-fs8.png"
          alt="UsabilityHub alternatives"
        />
      ),
    },
    {
      title: "6 Alternatives to UserZoom for UX Designers in 2024",
      linkHref: "https://www.userbrain.com/blog/userzoom-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/userzoom-fs8.png"
          alt="UserZoom alternatives"
        />
      ),
    },
    {
      title: "6 Userlytics Alternatives for UX Designers in 2024",
      linkHref: "https://www.userbrain.com/blog/userlytics-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/userlytics-fs8.png"
          alt="Userlytics alternatives"
        />
      ),
    },
    {
      title: "6 Best Loop11 Alternatives for UX Designers in 2024",
      linkHref: "https://www.userbrain.com/blog/loop11-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/loop11-fs8.png"
          alt="Loop11 alternatives"
        />
      ),
    },
    {
      title: "6 Best UserTesting Alternatives in 2024",
      linkHref: "https://www.userbrain.com/blog/usertesting-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/usertesting-fs8.png"
          alt="UserTesting alternatives"
        />
      ),
    },
    {
      title:
        "UserTesting vs. Trymata vs. Userbrain: Which Is The Best User Testing Tool?",
      linkHref:
        "https://www.userbrain.com/blog/trymata-vs-usertesting-vs-userbrain",
      image: (
        <StaticImage
          src="../images/compare/blog/usertesting-trymata-userbrain-fs8.png"
          alt="TryMata alternatives"
        />
      ),
    },
    {
      title: "6 Best-Rated Lookback Alternatives for User Testing in 2024",
      linkHref: "https://www.userbrain.com/blog/lookback-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/lookback-fs8.png"
          alt="Lookback alternatives"
        />
      ),
    },
    {
      title: "12 Best Dscout Alternatives for UX Research in 2024",
      linkHref: "https://www.userbrain.com/blog/dscout-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/dscout-fs8.png"
          alt="UsabilityHub alternatives"
        />
      ),
    },
    {
      title: "6 Best UX Tweak Alternatives for User Testing in 2024",
      linkHref: "https://www.userbrain.com/blog/ux-tweak-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/uxtweak-fs8.png"
          alt="UX Tweak alternatives"
        />
      ),
    },
    {
      title: "6 Best Hotjar Alternatives for Product Testing in 2024",
      linkHref: "https://www.userbrain.com/blog/hotjar-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/hotjar-fs8.png"
          alt="Hotjar alternatives"
        />
      ),
    },
    {
      title: "6 Best Userfeel Alternatives for UX Research in 2024",
      linkHref: "https://www.userbrain.com/blog/userfeel-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/userfeel-fs8.png"
          alt="Userfeel alternatives"
        />
      ),
    },
    {
      title: "6 Best Crazy Egg Alternatives for UX Research in 2024",
      linkHref: "https://www.userbrain.com/blog/crazyegg-alternatives",
      image: (
        <StaticImage
          src="../images/compare/blog/crazyegg-fs8.png"
          alt="Crazy Egg alternatives"
        />
      ),
    },
  ];

  return (
    <Layout
      title={"Userbrain vs. other user testing tools"}
      metaDescription={
        "How does Userbrain compare against the competition? We’ve crunched the numbers, so you don’ t have to.Here’ s how Userbrain stacks up."
      }
    >
      <article>
        <HeaderOverlap
          isDisplayCta
          overline={"Compare"}
          titleWidth={"12.6em"}
          title={
            <>
              Userbrain{" "}
              <span className={helperStyles.h1Serif}>
                {" "}
                vs. other user testing tools
              </span>
            </>
          }
          subtitle={
            "How does Userbrain compare against the competition? We’ve crunched the numbers, so you don’t have to. Here’s how Userbrain stacks up."
          }
          subtitleWidth={"32em"}
          staticImage={(className) => (
            <StaticImage
              className={className}
              src="../images/compare/header-fs8.png"
              alt="Userbrain vs. other user testing tools"
            />
          )}
        />

        <div className={helperStyles.container}>
          <section className={helperStyles.spacingLg}>
            <Headline
              title={"Userbrain vs. the competition"}
              titleWidth={"17em"}
              subtitle={
                "Thinking of making the switch to Userbrain? We’ve weighed up all the pros and cons, so you can make the right choice with peace of mind."
              }
              subtitleWidth={"32em"}
            />

            <CompareCard.Group className={helperStyles.spacingMd}>
              {listCenteredAllCompareArticles.map((item, index) => {
                return (
                  <CompareCard
                    key={index}
                    title={item.title}
                    image={item.image}
                    backgroundImage={item.backgroundImage}
                    text={item.text}
                    linkTo={item.linkTo}
                    color={item.color}
                    colorName={item.colorName}
                  />
                );
              })}
            </CompareCard.Group>
          </section>
        </div>

        <section
          className={cn(
            helperStyles.container,
            helperStyles.containerColor,
            helperStyles.spacingLg,
            styles.sectionBlog
          )}
        >
          <Headline
            className={helperStyles.spacingMd}
            title={"Alternatives to popular user testing tools"}
            titleWidth={"16em"}
            subtitle={
              "Itchy feet? Thinking of breaking up with your current user testing tool? Here are the best alternatives for each platform."
            }
            subtitleWidth={"32em"}
          />

          <BlogTeaser.Group className={helperStyles.spacingMd}>
            {listCenteredAllAlternativeBlogArticles.map((item, index) => {
              return (
                <BlogTeaser
                  key={index}
                  title={item.title}
                  image={item.image}
                  linkHref={item.linkHref}
                />
              );
            })}
          </BlogTeaser.Group>
        </section>

        <div className={helperStyles.container}>
          <Headline
            className={helperStyles.spacingLg}
            title={
              "Userbrain is trusted by UX & product teams around the world:"
            }
            titleWidth={"18em"}
          />
          <TrustBox className={helperStyles.spacingMd} />
        </div>

        <div className={helperStyles.container}>
          <Testimonials
            className={helperStyles.spacingLg}
            testimonials={[
              testimonialJamie,
              testimonialPascal,
              testimonialPaul,
            ]}
          />
          {/* <Brands className={helperStyles.spacingLg} hideDescription={true} /> */}
        </div>

        <div className={helperStyles.container}>
          <GetStartedBox
            className={helperStyles.spacingLg}
            title={
              <>
                Ready for your first{" "}
                <HighlightedText cursor>user test?</HighlightedText>
              </>
            }
            textBottom={
              "Get set up in minutes. Your first test is ready in a few hours."
            }
          />
          <Testimonials
            className={helperStyles.spacingLg}
            testimonials={[
              testimonialJoshua,
              testimonialAaron,
              testimonialThomas,
              testimonialDamon,
            ]}
          />
        </div>
      </article>
    </Layout>
  );
};

export default ContentPage;
